/* SCSS Variables */

/*--------------
  Font
  -------------- */
$base-font: 'Spoqa Han Sans Neo', 'Source Sans Pro', Apple SD Gothic Neo, Nanum Barun Gothic, Nanum Gothic, Verdana, Arial, Dotum, sans-serif;
$code-font: Menlo, 'Ubuntu Mono', Monaco, 'Spoqa Han Sans Neo', monospace;
$code-gist-font: Menlo, 'Ubuntu Mono', Monaco, 'Spoqa Han Sans Neo', monospace;

$base-font-size: 18px;
$base-font-size-sm: 14px;

$mobile-font-size: 16px;


/*--------------
  Color
  -------------- */
$base-color: $oc-gray-8;
$base-lighten-color: $oc-gray-6;

$background-color: $oc-white;
$background-over-color: $oc-gray-1;

$text-color: $oc-gray-8;
$link-color: $oc-blue-8;
$code-color: $oc-indigo-9;
$code-background-color: $oc-gray-1;
$table-border-color: $oc-gray-2;
$table-background-color: $oc-gray-1;

$divider-color: $oc-gray-1;


/*--------------
  List
  -------------- */

$li-bottom-space: 0.8em;
$li-bullets-width: 1.7em;
$li-line-height: 1.55;

$ul-bullets-font: inherit;
$ul-bullets-font-size: 1.2em;
$ul-bullets-font-line-height: 1.2;
$ul-bullets-right-space: 0.65em;

$ol-bullets-font: inherit;
$ol-bullets-font-size: 1em;
$ol-bullets-font-line-height: inherit;
$ol-bullets-right-space: 0.65em;

$li-child-size-ratio: 0.95;

$dt-width: 180px;
$dt-dd-space: 20px;
$dd-position: $dt-width+$dt-dd-space
